<template>
  <div class="app-container">
    
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>充值回收</span>
      </div>
      <el-form ref="form" label-width="160px" size="small" style="width:60%">
        <el-form-item label="钱包">
          <el-radio v-model="form.wallet_type" :label="1" border>DP钱包</el-radio>
          <el-radio v-model="form.wallet_type" :label="2" border>OTC钱包</el-radio>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="form.user_id"/>
        </el-form-item>
        <el-form-item label="操作">
          <el-radio v-model="form.type" :label="1" border>充值</el-radio>
          <el-radio v-model="form.type" :label="2" border>回收</el-radio>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="form.amount" type="number"/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm" size="small">重置</el-button>
          <el-button type="primary" @click="saveData" :loading="btnLoading" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      btnLoading: false,
      form: {
        wallet_type: 1,
        user_id: '',
        type: 1,
        amount: '',
        remark: '',
      },
    };
  },
  created() {

  },
  methods: {
    resetForm() {
      this.form = {
        wallet_type: 1,
        user_id: '',
        type: 1,
        amount: '',
        remark: '',
      }
    },
    saveData() {
      this.btnLoading = true;
      var url = '';
      if (this.form.wallet_type == 1) {
        url = "/api/backend/dpWallet/charge"
      } else {
        url = "/api/backend/otcWallet/charge"
      }
      request({
        url: url,
        method: "post",
        data: this.form
      }).then(response => {
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 2000
        })
        // this.resetForm()
      }).finally(() => {
        this.btnLoading = false
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
